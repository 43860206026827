body {
  margin: 0;
  line-height: normal;
}

:root {
  --font-roboto-condensed: "Roboto Condensed";
  --header: Roboto;
  --font-black-han-sans: "Black Han Sans";
  --bodytext: Montserrat;
  --bodytext-size: 1.125rem;
  --header-size: 4.625rem;
  --sub-headers-size: 3.5rem;
  --font-size-221xl: 15rem;
  --bg: #0b1d26;
  --wei: #fff;
  --accent: #b8c5cc;
  --color-gainsboro: #d9d9d9;
  --gap-71xl: 5.625rem;
  --gap-8xl: 1.687rem;
  --br-8xs: 5px;
  --shadow: 0px 0px 100px #0003;
}
/*# sourceMappingURL=index.378eb311.css.map */
